import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import DocumentPreviewTablePanel from "../Table/DocumentPreviewTablePanel";
import DocumentPreviewEditPanel from "./DocumentPreviewEditPanel";

const DocumentPreviewRightPanel = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
    const { isShowEditPanel } = documentPreviewState;

    const memoDocumentPreviewTable = useMemo(() => {
        return <DocumentPreviewTablePanel />
    }, [])
    const memoDocumentPreviewEditPanel = useMemo(() => {
        return <DocumentPreviewEditPanel />
    }, [])

    return <>
        <div className={`main-comp-wrapper${isShowEditPanel ? '' : ' im-hide-side-form-draggable'}`}>

            <SliderPanel
                isOpen={true}
                draggable={true}
                leftSectionWidth={isShowEditPanel ? "80%" : "100%"}
                rightSectionWidth={isShowEditPanel ? "20%" : "0%"}
                leftChildren={memoDocumentPreviewTable}
                rightChildren={memoDocumentPreviewEditPanel}
            />
        </div>
    </>
}

export default memo(DocumentPreviewRightPanel);